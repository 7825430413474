import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import { Header, Subheader, Container, FrameImageContainer } from './review'

const SnapFrames = () => {
  const { frameProduct } = useStaticQuery(graphql`
    query SnapFramesQuery {
      frameProduct: file(relativePath: { eq: "homepage/colored-frames.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <>
      <SEO
        title="Setup Guide: Snap-On Frames"
        path="/snapframesetup/"
        description="Our step-by-step guide for customzing your Skylight Frame with snap on styles."
      />
      <Container>
        <Header>Coming Soon...</Header>
        <Subheader>
          Our step-by-step guide for customzing your Skylight Frame with snap on styles.
        </Subheader>
        <FrameImageContainer>
          <GatsbyImage
            image={frameProduct.childImageSharp.gatsbyImageData}
            alt="Skylight Frames in all colors, red, gold, silver, white, black"
          />
        </FrameImageContainer>
      </Container>
    </>
  )
}

export default SnapFrames
